import PropTypes from "prop-types";
import SpinnerSVG from "../../../../static/img/spinner.svg";
import WhiteSpinnerSVG from "../../../../static/img/spinner-white.svg";
import { memo } from "react";

const Spinner = memo(props => (
	<img src={props.white ? WhiteSpinnerSVG : SpinnerSVG} width={props.width} height={props.height} />
));

Spinner.displayName = "Spinner";

export default Spinner;

Spinner.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	white: PropTypes.bool,
};

Spinner.defaultProps = {
	white: false,
};
