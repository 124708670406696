import * as Sentry from "@sentry/browser";

const isNotLocal = typeof window.data.environment !== "undefined" && window.data.environment !== "local";

Sentry.init({
	dsn: window.data.sentry_url,
	environment: window.data.environment,
	release: "0.1",
});

Sentry.addGlobalEventProcessor(async event => {
	if (event.exception && event.exception.values) {
		const customEvent = new CustomEvent("caughtException", { detail: event.exception.values[0] });
		document.dispatchEvent(customEvent);
	}

	return isNotLocal ? event : null;
});
