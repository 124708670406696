import get from "lodash/get";
import eachRight from "lodash/eachRight";
import replace from "lodash/replace";
import upperFirst from "lodash/upperFirst";

export const dictionary = window.data.i18n;

export const trans = function (label, replaceValue = {}, transReplaces = {}, ucfirst = false) {
	let translation = get(dictionary, label.toLowerCase());

	if (!translation) {
		dictionary[label.toLowerCase()] = label;
		translation = label;
		console.warn("Missing translation for: ", translation);
	}

	eachRight(replaceValue, (paramVal, paramKey) => {
		translation = replace(translation, `:${paramKey}`, paramVal);
	});

	eachRight(transReplaces, (paramVal, paramKey) => {
		translation = replace(translation, `:${paramKey}`, trans(paramVal));
	});

	if (ucfirst) {
		translation = upperFirst(translation);
	}

	return translation;
};

export const uctrans = function (label, replaceValue = {}, transReplaces = {}) {
	return trans(label, replaceValue, transReplaces, true);
};

export const hasTrans = function (label) {
	return typeof get(dictionary, label.toLowerCase()) !== "undefined";
};
