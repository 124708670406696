// laravel stuff
import axios from "axios";
import * as notifier from "./react/components/general/notifications";
import { trans, hasTrans } from "./lib/Translator";

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
	window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
	console.error("CSRF token not found");
}

// Intercept axios requests that return a 401 response (logged out)
window.axios.handleInvalidRequestExceptions = true;
window.axios.interceptors.response.use(
	response => response,
	error => {
		if (error.response) {
			if (error.response.data && error.response.data.redirect_path) {
				window.location = error.response.data.redirect_path;
			}
		}

		const label = `general.status_codes.${error.response.status}`;
		if (hasTrans(label)) {
			notifier.error(trans(label));

			return;
		}

		return Promise.reject(error);
	},
);

// initialize sentry
import "./sentry";

// initialize eva
import * as eva from "eva-icons";
window.eva = eva;

window.notifier = notifier;
