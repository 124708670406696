import { useState, useEffect } from "react";
import Notification from "./Notification";
import { addListener, getNotifications } from "../index";

export default function Notifications() {
	const [notifications, setNotifications] = useState(getNotifications());

	useEffect(() => {
		addListener(setNotifications);
	}, []);

	return (
		<div className="notifications_container">
			{Object.keys(notifications).map(id => (
				<Notification
					key={id}
					id={parseInt(id)}
					title={notifications[id].title}
					type={notifications[id].type}
					text={notifications[id].text}
					expires={notifications[id].expires}
				/>
			))}
		</div>
	);
}
