import classNames from "classnames";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import EvaIcon from "../../EvaIcon";
import { removeNotification } from "../index";
import Spinner from "../../Spinner";

export default function Notification({ id, expires, title, text, type }) {
	const [destroyed, setDestroyed] = useState(false);
	const [shown, setShown] = useState(true);

	const hide = useCallback(() => {
		if (type !== "loading") {
			setShown(false);
			setTimeout(() => {
				setDestroyed(true);
				setTimeout(() => {
					removeNotification(id);
				}, 300);
			}, 300);
		}
	}, [type, id]);

	useEffect(() => {
		const intervalID = expires
			? setInterval(() => {
					const isExpired = new Date().valueOf() > expires;

					if (isExpired) {
						hide();
						clearInterval(intervalID);
					}
			  }, 100)
			: null;

		return () => clearInterval(intervalID);
	}, [expires, hide]);

	const classes = classNames("notification", type, {
		enter: shown && !destroyed,
		leave: !shown && !destroyed,
		destroyed,
	});

	const icons = {
		error: <EvaIcon type="alert-triangle-outline" fill="currentColor" height="25" width="25" />,
		success: <EvaIcon type="checkmark-circle-2-outline" fill="currentColor" height="25" width="25" />,
		loading_destroy: <Spinner width={23} white />,
		loading: <Spinner width={23} white />,
		warning: <EvaIcon type="alert-circle-outline" fill="currentColor" height="25" width="25" />,
	};

	return (
		<div onMouseDownCapture={hide} onTouchStart={hide} className={classes}>
			<div className="notification_icon">{icons[type]}</div>
			<div className="notification_content">
				<div className="notification_title">{title}</div>

				<div className="notification_text" dangerouslySetInnerHTML={{ __html: text }}></div>
			</div>
			{type !== "loading" && <i className="fa fa-times notification_close_button" />}
		</div>
	);
}

Notification.propTypes = {
	id: PropTypes.number,
	expires: PropTypes.number,
	title: PropTypes.string,
	text: PropTypes.string.isRequired,
	type: PropTypes.string,
};
