import { memo } from "react";
import PropTypes from "prop-types";
import { icons } from "eva-icons";
import CalculatorIcon from "./custom-icons/CalculatorIcon";

const EvaIcon = ({ type, className, fill, width, height, style }) => {
	if (!type) {
		return null;
	}

	if (type.startsWith("abn360-")) {
		// custom icons
		switch (type) {
			case "abn360-calculator":
				return <CalculatorIcon className={className} fill={fill} width={width} height={height} />;
			default:
				return null;
		}
	}

	if (!Object.prototype.hasOwnProperty.call(icons, type)) {
		return null;
	}

	return (
		<span
			className={className}
			style={style}
			dangerouslySetInnerHTML={{
				__html: icons[type].toSvg({
					fill,
					width,
					height,
				}),
			}}
		/>
	);
};

export default memo(EvaIcon);

EvaIcon.propTypes = {
	type: PropTypes.string.isRequired,
	className: PropTypes.string,
	fill: PropTypes.string,
	style: PropTypes.object,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
