/**
 *
 * Notification system (uses the Notifications react component)
 */

const defaultNotificationTime = 7000;

let autoIncrementedId = 0;

if (!window.notification_listeners) {
	window.notification_listeners = [];
}

if (!window.notifications_store) {
	window.notifications_store = {};

	const localStorageNotifications =
		localStorage.notifications_store !== undefined ? JSON.parse(localStorage.notifications_store) : [];

	for (let i = 0; i < localStorageNotifications.length; i++) {
		const notification = localStorageNotifications[i];
		window.notifications_store[i] = {
			...notification,
			expires: new Date().valueOf() + notification.duration,
		};
		autoIncrementedId = i;
	}

	localStorage.removeItem("notifications_store");
}

const notifyListeners = () => {
	window.notification_listeners.forEach(listener =>
		listener({
			...window.notifications_store,
		}),
	);
};

export const updateNotification = (id, updatedValues, duration = defaultNotificationTime) => {
	window.notifications_store[id] = {
		...window.notifications_store[id],
		...updatedValues,
		expires: new Date().valueOf() + duration,
	};

	notifyListeners();
};

export const removeNotification = id => {
	delete window.notifications_store[id];
	notifyListeners();
};

/**
 *
 * @param {string} type
 * @param {string} text
 * @param {string|null} title
 * @param  {number=5000} duration
 * @returns {number}
 */

export const queueNotification = (type, text, title, duration = defaultNotificationTime) => {
	const localNotificationStore =
		localStorage.notifications_store !== undefined ? JSON.parse(localStorage.notifications_store) : [];
	localNotificationStore.push({
		title,
		text,
		type,
		duration,
	});
	localStorage.notifications_store = JSON.stringify(localNotificationStore);
};

export const notify = (type, text, title, duration = defaultNotificationTime) => {
	if (typeof text !== "string") {
		text = text.toString();
	}

	window.notifications_store[++autoIncrementedId] = {
		title,
		text,
		type,
		duration,
		expires: new Date().valueOf() + duration,
	};

	notifyListeners();

	return autoIncrementedId;
};

/**
 * Some shortcuts
 */
export const success = (text, title = "", duration = defaultNotificationTime) =>
	notify("success", text, title, duration);
export const error = (text, title = "", duration = defaultNotificationTime) => notify("error", text, title, duration); // an alias
export const failure = (text, title = "", duration = defaultNotificationTime) => notify("error", text, title, duration);
export const debug = (text, title = "", duration = defaultNotificationTime * 10) =>
	notify("warning", text, title, duration);
export const warning = (text, title = "", duration = defaultNotificationTime) =>
	notify("warning", text, title, duration);

/**
 *
 * @param {string} text Text of the loader
 * @param {string|null} title Title of the loader
 * @param {number} duration Duration of the loader
 * @param {boolean} disableUnloadWarning
 * @returns {{success: function(*, *=, *=), failure: function(*, *=, *=), destroy: function()}}
 */
export const createLoader = (
	text,
	title = null,
	duration = defaultNotificationTime * 10000,
	disableUnloadWarning = false,
) => {
	const notificationId = notify("loading", text, title, duration);

	if (!disableUnloadWarning) {
		// enable the unload event
		window.onbeforeunload = function () {
			return "An action is still ongoing";
		};

		window.unload = function () {
			return "An action is still ongoing";
		};
	}

	const disableUnloadEvent = () => {
		window.onbeforeunload = null;
		window.unload = null;
	};

	return {
		/**
		 * Change the loader to a success notification
		 * @param {string|null} text
		 * @param {string|null} title
		 * @param {number} duration
		 */
		success: (text = null, title = null, duration = defaultNotificationTime) => {
			disableUnloadEvent();

			const data = {};

			if (title) {
				data.title = title;
			}

			if (text) {
				data.text = text;
			}

			return updateNotification(notificationId, { ...data, type: "success" }, duration);
		},

		/**
		 * Change the loader to a failure notification
		 * @param {string} text
		 * @param {string|null} title
		 * @param {number} duration
		 */
		failure: (text = null, title = null, duration = defaultNotificationTime) => {
			disableUnloadEvent();

			const data = {};

			if (title) {
				data.title = title;
			}

			if (text) {
				data.text = text;
			}

			return updateNotification(notificationId, { ...data, type: "error" }, duration);
		},

		update: (text = null, title = null) => {
			const data = {};

			if (title) {
				data.title = title;
			}

			if (text) {
				data.text = text;
			}

			return updateNotification(notificationId, { ...data }, duration);
		},

		/**
		 * Destroy the loader
		 */
		destroy: () => {
			disableUnloadEvent();
			return updateNotification(notificationId, { type: "loading_destroy" }, 0);
		},

		displayErrors: errors => {
			Object.keys(errors).forEach((key, index) => {
				if (index === 0) {
					return updateNotification(notificationId, { text: errors[key], type: "error" });
				} else {
					error(upperFirst(errors[key]));
				}
			});
		},
	};
};

export const getNotifications = () => window.notifications_store;

export const addListener = listener => {
	if (!window.notification_listeners) {
		window.notification_listeners = [];
	}

	window.notification_listeners.push(listener);
};

export const setListeners = listeners => {
	window.notification_listeners = listeners;
};

export const types = {
	NOTICE: "NOTICE",
	WARNING: "WARNING",
	ERROR: "ERROR",
};

export const parseSessionFlasherNotifications = () => {
	const duration = 6000;

	if (window.data.flash_messages) {
		if (window.data.flash_messages.errors) {
			window.data.flash_messages.errors.forEach((error, index) => {
				delete window.data.flash_messages.errors[index];
				notify("error", error, null, duration);
			});
		}

		if (window.data.flash_messages.warnings) {
			window.data.flash_messages.warnings.forEach((warning, index) => {
				delete window.data.flash_messages.warnings[index];
				notify("warning", warning, null, duration);
			});
		}
		if (window.data.flash_messages.notices) {
			window.data.flash_messages.notices.forEach((notice, index) => {
				delete window.data.flash_messages.notices[index];
				notify("success", notice, null, duration);
			});
		}
		if (window.data.flash_messages.success) {
			window.data.flash_messages.success.forEach((notice, index) => {
				delete window.data.flash_messages.success[index];
				notify("success", notice, null, duration);
			});
		}
		window.data.flash_messages = {
			success: [],
			notices: [],
			warnings: [],
			errors: [],
		};
	}
};

import upperFirst from "lodash/upperFirst";
import Notifications from "./components/Notifications";

export { Notifications };
